<script>
export default {
	name: 'App',
	data() {
		return {
			currentBG: 1,
			albums: false,
		}
	},
	computed: {},
	mounted() {
		setInterval(() => this.changeBackground(), 5000)
	},
	unmounted() {
		clearInterval(this.interval)
	},
	methods: {
		changeBackground() {
			this.currentBG = this.currentBG === 3 ? 1 : this.currentBG + 1
		},
	},
}
</script>

<template>
	<div class="background">
		<div class="left">
			<div
				class="bg"
				v-for="i in 3"
				:key="i"
				:class="{ visible: i === currentBG }"
				:style="{
					backgroundImage: `url(${`background/${i}.jpg`})`,
				}"
			/>
			<div class="content">
				<img class="logo" src="@/assets/logo.svg" alt="Julia Brite" />
				<div class="main" :class="{ visible: !albums }">
					<div class="new-album">
						The album is coming out this year.<br />
						Stay Tuned!
					</div>
					<div class="bio">
						Julia is a Ukrainian born Russian-American singer
						songwriter. She started learning piano and singing when
						she was 7 and wrote her first piece of music at the age
						of 12. She studied at the Music College of Pop Music and
						Jazz Art in Moscow and was performing in local
						venues.<br /><br />
						Then she moved to the US where she started working on
						her original music.<br /><br />
						In 2017 Julia featured on the song “Frozen fire” by the
						legendary French artist MC Solaar which was released on
						the album entitled “Geopoetique” and certified double
						platinum in France in 2018. In 2019 Julia collaborated
						with Javo Barrera on the song “Into the sunset” and she
						performed it in LA’s famous venue Baked potato. In 2020
						Julia Released her solo single and the video “it’s all
						yours”. This year Julia’s album is finally going to be
						released.
					</div>
					<div class="previous-covers" @click="albums = true">
						<img src="./assets/covers/frozen.png" class="cover" />
						<img
							src="./assets/covers/into_the_sunset.png"
							class="cover"
						/>
						<img src="./assets/covers/home.png" class="cover" />
						<div class="title">Previous<br />releases</div>
					</div>
					<div class="shares">
						<a
							href="https://www.youtube.com/channel/UCcf-Iiq0Z37GoHx3F9FvTgw"
						>
							<i class="fa fa-youtube" aria-hidden="true"></i>
						</a>
						<a href="https://www.facebook.com/julbrite">
							<i class="fa fa-facebook" aria-hidden="true"></i>
						</a>
						<a href="https://www.instagram.com/JuliaBrite/">
							<i class="fa fa-instagram" aria-hidden="true"></i>
						</a>
					</div>
					<a
						href="mailto:info@growingsoulrecords.com"
						class="contact"
						onclick="ga('send', 'event', 'Contact', 'clicked')"
						>info@growingsoulrecords.com</a
					>

					<div class="copyright">
						© Growing Soul Records {{ new Date().getFullYear() }}
					</div>
				</div>
				<div class="album-list" :class="{ visible: albums }">
					<div class="release">
						<img
							src="./assets/covers/its_all_yours.png"
							class="cover"
						/>
						<div class="downloads">
							<a
								href="https://www.youtube.com/watch?v=KKcpYrXp8n0"
								target="_blank"
								onclick="ga('send', 'event', 'download youtube', 'home')"
								>YouTube</a
							>
							<a
								href="https://apple.co/3jRFIGn"
								target="_blank"
								onclick="ga('send', 'event', 'download itunes', 'home')"
								>iTunes</a
							>
							<a
								href="https://apple.co/3jRFIGn"
								target="_blank"
								onclick="ga('send', 'event', 'download apple music', 'home')"
								>Apple Music</a
							>
							<a
								href="https://amzn.to/3bK0ept"
								target="_blank"
								onclick="ga('send', 'event', 'download amazon', 'home')"
								>Amazon</a
							>
							<a
								href="https://spoti.fi/3hiCKsO"
								target="_blank"
								onclick="ga('send', 'event', 'download spotify', 'home')"
								>Spotify</a
							>
						</div>
					</div>
					<div class="release">
						<img src="./assets/covers/home.png" class="cover" />
						<div class="downloads">
							<a
								href="https://www.youtube.com/watch?v=JgHr3xNYXLw"
								target="_blank"
								onclick="ga('send', 'event', 'download youtube', 'home')"
								>YouTube</a
							>
							<a
								href="https://apple.co/3fNo3yp"
								target="_blank"
								onclick="ga('send', 'event', 'download itunes', 'home')"
								>iTunes</a
							>
							<a
								href="https://apple.co/3fNo3yp"
								target="_blank"
								onclick="ga('send', 'event', 'download apple music', 'home')"
								>Apple Music</a
							>
							<a
								href="https://amzn.to/2LDgA6U"
								target="_blank"
								onclick="ga('send', 'event', 'download amazon', 'home')"
								>Amazon</a
							>
							<a
								href="https://spoti.fi/2TcVD73"
								target="_blank"
								onclick="ga('send', 'event', 'download spotify', 'home')"
								>Spotify</a
							>
						</div>
					</div>
					<div class="release">
						<img
							src="./assets/covers/into_the_sunset.png"
							class="cover"
						/>
						<div class="downloads">
							<a
								href="https://apple.co/2SaXxWD"
								target="_blank"
								onclick="ga('send', 'event', 'download itunes', 'sunset')"
								>iTunes</a
							>
							<a
								href="https://amzn.to/2HwTXC4"
								target="_blank"
								onclick="ga('send', 'event', 'download amazon', 'sunset')"
								>Amazon</a
							>
						</div>
					</div>
					<div class="release">
						<img src="./assets/covers/frozen.png" class="cover" />
						<div class="downloads">
							<a
								href="https://itunes.apple.com/bf/album/géopoétique/1273735043?ign-mpt=uo%3D2"
								target="_blank"
								onclick="ga('send', 'event', 'download iTunes', 'frozen')"
								>iTunes</a
							>
						</div>
					</div>
					<div class="back" @click="albums = false">
						<i class="fa fa-close" aria-hidden="true"></i> Go back
					</div>
				</div>
			</div>
		</div>
		<div class="right">
			<div
				class="bg"
				v-for="i in 3"
				:key="i"
				:class="{ visible: i === currentBG }"
				:style="{
					backgroundImage: `url(${`background/${i}.jpg`})`,
				}"
			/>
		</div>
	</div>
</template>

<style scoped>
.background {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.left {
	width: 40vw;
	height: 100vh;
	overflow: hidden;
	position: relative;

	.bg {
		background-position-x: 10vw;
		background-repeat: no-repeat;
	}
}

.right {
	width: 60vw;
	height: 100vh;
	overflow: hidden;
	position: relative;
}

.bg {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: left;
	position: absolute;
	transition: opacity 800ms ease;
	opacity: 0;

	&.visible {
		opacity: 1;
	}
}

.content {
	width: 100%;
	height: 100%;
	backdrop-filter: blur(100px);
	padding: calc(2vw + 20px) 2vw;
	font-size: 13px;
	overflow-y: auto;
	overflow-x: hidden;

	.logo {
		max-width: 200px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}

.main {
	height: 100%;
	padding-top: 80px;
	display: flex;
	align-items: center;
	flex-direction: column;
	transition:
		opacity 400ms ease,
		transform 400ms ease;
	transform: translateX(-120%);

	&.visible {
		transform: translateX(0);
	}

	.new-album {
		font-size: 18px;
		color: #fff;
		text-align: center;
		margin-bottom: 20px;
	}

	.bio {
		max-width: 500px;
		background: -webkit-linear-gradient(#fff, rgba(255, 255, 255, 0.6));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		padding: 0 20px;
	}
}

.album-list {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	transform: translateX(100%);
	transition: transform 400ms ease;
	padding: calc(2vw + 20px) 2vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&.visible {
		transform: translateX(0);
	}

	.release {
		width: 100%;
		max-width: 300px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		&:first-child {
			margin-top: auto;
		}
	}

	.cover {
		width: 80px;
		height: 80px;
		border-radius: 7px;
	}

	.downloads {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: start;
		flex-wrap: wrap;

		a {
			width: auto;
			color: #fff;
			margin-left: 10px;
			margin-bottom: 5px;
			font-size: 14px;
			display: block;
			transition: color 1500ms ease;
		}
	}

	&.dark {
		.downloads a {
			color: #000;
		}
	}

	.back {
		width: 100%;
		height: 50px;
		text-align: center;
		color: #fff;
		transition:
			opacity 300ms ease,
			color 1500ms ease;
		margin-top: auto;
		line-height: 50px;
		cursor: pointer;
		opacity: 0.6;

		&:hover {
			opacity: 1;
		}

		&.dark {
			color: #000;
		}
	}
}

.previous-covers {
	width: 160px;
	height: 110px;
	flex-shrink: 0;
	cursor: pointer;
	position: relative;
	padding-bottom: 10px;
	padding-top: 20px;
	margin-top: auto;

	.cover {
		position: absolute;
		width: 70px;
		height: 70px;
		border-radius: 7px;
		transition: transform 500ms ease;
	}

	.cover:nth-child(1) {
		margin-left: 20px;
		transform: scale(0.92);
	}

	.cover:nth-child(2) {
		margin-left: 10px;
		transform: scale(0.96);
	}

	.title {
		position: absolute;
		right: 0;
		color: #fff;
		font-size: 14px;
		top: 20px;
		opacity: 0.6;
		text-align: right;
		line-height: 1.2;
		margin-top: 20px;
		transition:
			opacity 300ms ease,
			color 1500ms ease;
	}

	&:hover {
		.title {
			opacity: 1;
		}

		.cover:nth-child(1) {
			transform: translateX(10px) scale(0.92);
		}

		.cover:nth-child(2) {
			margin-left: 10px;
			transform: translateX(5px) scale(0.96);
		}
	}
}

.shares {
	width: 100%;
	max-width: 160px;
	display: flex;
	justify-content: space-around;

	a {
		color: #fff;
		width: 100%;
		height: 44px;
		text-align: center;
		line-height: 44px;
		font-size: 26px;
		opacity: 0.6;
		transition:
			opacity 300ms ease,
			color 1500ms ease;

		&:hover {
			opacity: 1;
		}
	}
}

.contact {
	color: #fff;
	font-size: 11px;
	opacity: 0.7;
	transition:
		opacity 300ms ease,
		color 1500ms ease;
	margin-bottom: 5px;

	&:hover {
		opacity: 1;
	}
}

.copyright {
	color: #fff;
	font-size: 11px;
	opacity: 0.7;
	padding-bottom: 20px;

	transition:
		opacity 300ms ease,
		color 1500ms ease;
}

@media (max-width: 740px) {
	.background {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
	}

	.left {
		width: 100vw;
		height: auto;

		.bg {
			background-position-x: 0vw;
			background-position-y: -80vh;
			background-repeat: no-repeat;
		}
	}

	.right {
		width: 100vw;
		height: 70vh;
	}

	.bg {
		background-position: center;
	}

	.main {
		padding-top: 60px;
	}

	.album-list {
		padding: 8vw;
	}
}
</style>
